
    import {Component, Prop, Vue} from "vue-property-decorator";
    import Exhibition from "@/components/news/Exhibition";

    @Component
    export default class ExhibitionCard extends Vue {
        @Prop() exhibition!: Exhibition;

        locale = localStorage.getItem("lang");

        getLocale() {
            let locale = localStorage.getItem("lang");
            if (locale) {
                return this.locale = locale
            } else {
                return this.locale = 'de'
            }
        }
    }
