import { Locales } from "./locales";

import en from "./en";
import de from "./de";

export const messages = {
    [Locales.EN]: en,
    [Locales.DE]: de
};

export const defaultLocale = Locales.DE;