
import {Component, Prop, Vue} from "vue-property-decorator";
import Contact from "@/components/contact/contact";

@Component
export default class ContactCard extends Vue {
  @Prop() private contact!: Contact;

  constructor() {
    super();
  }
}
