export default class Exhibition {
    id: string;
    img: string;
    name: string;
    description: string;
    start: Date;
    end: Date;
    city: string;
    country: string;
    href: string;

    constructor(id: string, img: string, name: string, description : string, start: Date, end: Date, city: string, country: string, href:string) {
        this.id = id;
        this.img = img;
        this.name = name;
        this.description = description;
        this.start = start;
        this.end = end;
        this.city = city;
        this.country = country;
        this.href = href
    }
}