
import {Component, Vue} from "vue-property-decorator";
import {DBService} from "@/components/Database/DBService";

@Component
export default class Company extends Vue {

  history: any[] = [];

  constructor() {
    super();
    this.getHistoryFromDB();
  }

  getLocale() {
    let locale = localStorage.getItem("lang");
    if (locale) {
      return locale
    } else {
      return 'de'
    }
  }

  async getHistoryFromDB() {
    const history = (await DBService.getInstance().getHistory());
    if (history) {
      this.history = history;
    }
  }

  team = [
    {src: require('@/assets/team/team1.jpg')},
    {src: require('@/assets/team/team4.jpg')},
  ]
}
