import { render, staticRenderFns } from "./CategoryCard.vue?vue&type=template&id=142b8d4c&scoped=true"
import script from "./CategoryCard.vue?vue&type=script&lang=ts"
export * from "./CategoryCard.vue?vue&type=script&lang=ts"
import style0 from "./CategoryCard.vue?vue&type=style&index=0&id=142b8d4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142b8d4c",
  null
  
)

export default component.exports