import category from "@/components/Categories/Category";

export default class EngineProxy {
    id: string;
    name: string;
    position: number;
    subtitle: Map<String, String>;
    type: category;
    thumbnail: string;

    constructor(id: string, name: string, position: number, subtitle: Map<String,String>, thumbnail: string, type: category) {
        this.id = id;
        this.name = name;
        this.position = position;
        this.subtitle = subtitle;
        this.thumbnail = thumbnail;
        this.type = type;
    }
}