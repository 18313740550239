
    import {Component, Prop, Vue} from "vue-property-decorator";
    import Engine from "@/components/products/Engine";

    @Component
    export default class RequestDialog extends Vue {
        @Prop() engine!: Engine;

        dialog = false;

        name:string = "";
        company:string = "";
        mail:string = "";
        subject:string = "";
        message:string = "";

        openMail() {
            let str = ('mailto:info@burri.de' + ('?subject=') + (this.subject) + ('&body=') + (this.message)
                + '%0D%0A' + (this.name) + '%0D%0A' + (this.company))
            window.open(str)
        }
    }
