
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {Locales} from "@/i18n/locales";

    @Component
    export default class AppBar extends Vue {
        @Prop() readonly category!: string;

        constructor() {
            super();
        }

        sideNav = false;
        prominent = false;
        isMobile = false;


        mounted () {
            this.onResize()
            window.addEventListener('resize', this.onResize, { passive: true })
        }
        onResize () {
            this.isMobile = window.innerWidth < 600
        }

        categories = [
            {category: this.$t("categories.home"), component: '/home'},
            {category: this.$t("categories.products"), component: "/products"},
            {category: this.$t("categories.company"), component: "/company"},
            {category: this.$t("categories.career"), component: "/career"},
            {category: this.$t("categories.news"), component: "/news"},
            {category: this.$t("categories.contact"), component: "/contact"},
        ]

        search = '';

        languages = [
            {language: 'Deutsch', locale: Locales.DE, icon: "de"},
            {language: 'English', locale: Locales.EN, icon: "gb"},
        ];

        changeLanguage(lang: string) {
            localStorage.setItem("lang", lang);
            this.$i18n.i18next.changeLanguage(localStorage.getItem("lang"));
            window.location.reload();
        }

        getLanguageIcon() {
            let lang = localStorage.getItem("lang")
            if(lang == "en") {
                return "gb"
            } else if(lang){
                return lang
            } else {
                return 'de'
            }
        }
    }
