export default class Contact {
    id: string;
    img: string;
    location: string;
    firstname: string;
    lastname: string;
    phone: string;
    mail: string;
    fax: string;
    adress: string;

    constructor(id: string, img: string, location: string, firstname: string, lastname: string, phone: string, mail:string, fax: string, adress: string) {
        this.id = id;
        this.img = img;
        this.location = location;
        this.firstname = firstname;
        this.lastname = lastname;
        this.phone = phone;
        this.mail = mail;
        this.fax = fax;
        this.adress = adress;
    }
}