
import {Component, Prop, Vue} from "vue-property-decorator";
import ContactCard from "@/components/contact/ContactCard.vue";
import {DBService} from "@/components/Database/DBService";

@Component({
  components: {ContactCard}
})
export default class Contact extends Vue {
  @Prop() private contact!: Contact;

  contacts: any[] = [];

  constructor() {
    super();
    this.getContactsFromDB()
  }

  async getContactsFromDB() {
    const contacts = (await DBService.getInstance().getContacts());
    if (contacts) {
      this.contacts = [];
      this.contacts = contacts;
    }
  }
}
