

    import {Component, Vue} from "vue-property-decorator";

    @Component
    export default class Footer extends Vue {
        constructor() {
            super();
        }
        icons = [];

        /*icons = [
            {icon: 'mdi-linkedin', link: "https://www.linkedin.com/company/burriwzm/"},
            {icon: 'mdi-youtube', link: "https://www.youtube.com/channel/UCobQ56_G4C7t3kTbRCIUxOQ"},
            {icon: 'mdi-facebook', link: "https://www.facebook.com/Burri.GearGrindTec/"},
            {icon: 'mdi-instagram', link: "https://www.instagram.com/burriwzm/"},
            {icon: 'mdi-twitter', link: "https://twitter.com/burri_wzm"}

        ];*/
    }
