
import Vue from 'vue';
import Home from "@/views/Home.vue";
import Footer from "@/components/Footer.vue";
import AppBar from "@/components/AppBar.vue";

export default Vue.extend({
  name: 'App',

  components: {
    Footer,
    AppBar,
    Home
  },

  data: () => ({}),
});
