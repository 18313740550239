import category from "@/components/Categories/Category";
import db from "@/firebaseConfig";

export default class Engine {
    id: string;
    name: string;
    header: string;
    imgs: string[];
    technical_data: Map<string, string>;
    notes: string[];
    description: string;
    advantages: string[];

    constructor(id: string, name: string, header: string, imgs: string[], technical_data: Map<string, string>,
                notes: string[], description: string, advantages: string[]) {
        this.id = id;
        this.name = name;
        this.header = header;
        this.imgs = imgs;
        this.technical_data = technical_data;
        this.notes = notes;
        this.description = description;
        this.advantages = advantages;
    }

    toString() {
        return this.id + ', ' + this.name + ', ' + this.description;
    }
}
