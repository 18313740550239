
import {Component, Prop, Vue} from "vue-property-decorator";
import {DBService} from "@/components/Database/DBService";
import Exhibition from "@/components/news/Exhibition";
import ExhibitionCard from "@/components/news/ExhibitionCard.vue";
import InnovationCard from "@/components/news/InnovationCard.vue";

@Component({
  components: {ExhibitionCard, InnovationCard}
})
export default class News extends Vue {
  @Prop() exhibition!: Exhibition;
  private exhibitions: any[] = [];
  private innovation: any[] = [];

  constructor() {
    super();
    this.getNewsFromDB()
  }

  async getNewsFromDB() {
    const exhibitions = (await DBService.getInstance().getExhibitions());
    if (exhibitions) {
      this.exhibitions = [];
      this.exhibitions = exhibitions;
    }

    const innovation = (await DBService.getInstance().getInnovation());
    if (innovation) {
      this.innovation = [];
      this.innovation = innovation;
    }
  }


}
