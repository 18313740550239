
    import {Component, Vue} from "vue-property-decorator";
    import CategoryCard from "@/components/Categories/CategoryCard.vue";
    import {getCategories} from "@/components/Categories/Category";
    import {DBService} from "@/components/Database/DBService";
    import ProductCard from "@/components/products/ProductCard.vue";
    import EngineProxy from "@/components/products/EngineProxy";

    @Component({
        components: {ProductCard, CategoryCard}})
    export default class Products extends Vue {
        private proxies: EngineProxy[] | string[] = [];
        categories = getCategories();

        constructor() {
            super();
            this.getProxiesFromDB()
        }
        async getProxiesFromDB() {
            const proxies = (await DBService.getInstance().getProxies())
            this.proxies = proxies;
        }
        routeToClamping() {
            this.$router.push({path: 'clamping'});
        }
    }
