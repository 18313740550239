
import {Component, Vue} from "vue-property-decorator";
import {DBService} from "@/components/Database/DBService";

@Component
export default class Career extends Vue {

  jobs: any[] = [];

  constructor() {
    super();
    this.getJobsFromDB()
  }

  mail = ' mailto:karriere@burri.de';

  async getJobsFromDB() {
    const jobs = (await DBService.getInstance().getJobs());
    if (jobs) {
      this.jobs = [];
      this.jobs = jobs;
    }
  }

  readFile(file: string) {
    window.open(file, '_blank') //to open in new tab
  }
}
