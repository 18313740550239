

import {Component, Prop, Vue} from "vue-property-decorator";
import router from "@/router";
import EngineProxy from "@/components/products/EngineProxy";

@Component
export default class ProductCard extends Vue {
  @Prop() private proxy!: EngineProxy;

  locale = localStorage.getItem('lang');

  constructor() {
    super();
  }

  routeToEngine() {
    const id = this.proxy.id;
    router.push({name: 'enginePage', params: {'id': id}})
  }
}
