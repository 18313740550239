

import {Component, Vue} from "vue-property-decorator";
import {DBService} from "@/components/Database/DBService";

@Component
export default class Home extends Vue {
  slides: any[] = [];
  language: string | null = localStorage.getItem("lang");
  currentMonth = new Date().getMonth();
  isChristmas = false;

  constructor() {
    super();
    this.getSlideShowFromDB()
    this.language = localStorage.getItem("lang")
    if (this.currentMonth === 11) {
      this.isChristmas = true;
    }
  }

  async getSlideShowFromDB() {
    const slides = (await DBService.getInstance().getSlideShow());
    if (slides) {
      this.slides = slides;
    }
  }

  created() {
    if (localStorage.getItem("lang") === null)
      localStorage.setItem("lang", 'de')
  }

}
