<template>
    <div>
        <v-container class="mt-5">
            <h1 class="primary--text">{{$t('products.clamping')}}</h1>
            <v-row>
                <v-col class="col-md-6">
                    <v-carousel v-model="model"
                                progress hide-delimiters height="400px">
                        <v-carousel-item class="mx-auto" style="width: 300px"
                                         v-for="(img, i) in clamping_imgs.imgs"
                                         :key="i">
                            <v-img :src="img" class="carousel-img" contain></v-img>
                        </v-carousel-item>
                    </v-carousel>

                    <v-item-group class="hidden-sm-and-down">
                        <v-row>
                            <v-col
                                    v-for="(img, i) in this.clamping_imgs.imgs"
                                    :key="i"
                            >
                                <v-item class="active-btn" v-slot:default="{ active, toggle }">
                                    <v-card
                                            class="d-flex align-center"
                                            height="100"
                                            @click="model=i"
                                    >
                                        <v-img :src="img" class="btn-img"></v-img>
                                    </v-card>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>
                </v-col>
                <v-col class="pt-md-5 col-md-6">

                    <h3>{{this.$t("products.technical_data")}}</h3>
                    <v-simple-table class="secondary">
                        <tbody>
                        <tr
                                v-for="(data, i) in clamping_data[getLocale()]"
                                :key="i"
                        >
                            <td>{{ data }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>

            <v-row class="col-md-12">
                <v-col class="pt-md-10 col-md-6">
                    <p class="mt-3" v-html="$t('clamping.text')"></p>
                </v-col>

                <v-col class="col-md-6">
                    <h3>{{this.$t("products.advantages")}}</h3>
                    <v-simple-table class="primary white--text">
                        <tr v-for="(item, i) in clamping_advantages[getLocale()]"
                            :key="i">
                            <td class="pl-2 py-5">
                                <v-icon dark>mdi-check-circle</v-icon>
                            </td>
                            <td class="pl-2 py-5">{{" " + item }}</td>
                        </tr>
                    </v-simple-table>

                    <!--<p>{{clamping_advantages.notes[getLocale()]}}</p>-->
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import {DBService} from "@/components/Database/DBService";

    @Component
    export default class Clamping extends Vue {
        model = 0;
        clamping_imgs = [];
        clamping_advantages = [];
        clamping_data = []

        constructor() {
            super();
            this.getClampingFromDb()
        }

        getLocale() {
            let locale = localStorage.getItem("lang");
            if (locale) {
                return locale
            } else {
                return 'de'
            }
        }

        async getClampingFromDb() {
            const clamping_imgs = (await DBService.getInstance().getClampingImgs());
            if (clamping_imgs) {
                this.clamping_imgs = clamping_imgs;
            }
            const clamping_advantages = (await DBService.getInstance().getClampingAdvantages());
            if (clamping_advantages) {
                this.clamping_advantages = clamping_advantages;
            }
            const clamping_data = (await DBService.getInstance().getClampingData());
            if (clamping_data) {
                this.clamping_data = clamping_data;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .carousel-img {
        width: auto;
        height: 100%;
    }

    .btn-img {
        width: 2vw;
        height: 100%;
    }

    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }


</style>