import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        dark: false,

        themes: {
            dark: {
                primary: '#FF0000', // #E53935
                secondary: '#a4a6aa', // #FFCDD2
            },

            light: {
                primary: '#FF0000', // #E53935
                secondary: '#a4a6aa', // #FFCDD2
            },
        },
    },
})
