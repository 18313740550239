
    import {Component, Prop, Vue} from "vue-property-decorator";
    import Engine from "@/components/products/Engine";
    import RequestDialog from "@/components/products/RequestDialog.vue";
    import {DBService} from "@/components/Database/DBService";

    @Component({
        components: {RequestDialog}
    })

    export default class ProductContainer extends Vue {
        private engine: Engine | string = "";
        model = 0;
        constructor() {
            super();
            this.getEngineFromDB()

        }
        async getEngineFromDB() {
            const potentialEngine = (await DBService.getInstance().getEngineById(this.$route.params.id));
            if (potentialEngine) {
                this.engine = potentialEngine;
            }
          console.log(this.engine)

        }
    }
