import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/analytics';
import 'firebase/storage';
import firebase from "firebase/compat";

const firebaseConfig = {
    apiKey: "AIzaSyCTInsQVeDsUwXPAvijwlDCcKxbpQzE7jY",
    authDomain: "homepage-b20c9.firebaseapp.com",
    databaseURL: "https://homepage-b20c9.firebaseio.com",
    projectId: "homepage-b20c9",
    storageBucket: "homepage-b20c9.appspot.com",
    messagingSenderId: "152615092082",
    appId: "1:152615092082:web:282cffebcb38f2ce9727eb",
    measurementId: "G-P88Y53XE3F"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();
db.enablePersistence({synchronizeTabs:true})

export default db;
