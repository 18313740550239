enum Category {
    geargrinding = "geargrinding",
    profiling = "profiling",
    clamping = "clamping"
}

export function getCategories() {
    return Category;
}

export default Category
