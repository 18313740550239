export default {
    categories: {
        home: "Start",
        products: "Produkte",
        company: "Unternehmen",
        career: "Karriere",
        news: "Aktuelles",
        contact: "Kontakt"
    },

    home: {
        welcome: "Burri Werkzeugmaschinen GmbH & Co. KG",
        eco: "Wirtschaftlichkeit und Innovation made in Germany",

        welcome_text: "Wir sind der führende Hersteller von Schleifschnecken-Profiliermaschinen und die weltweit " +
            "einzige Firma die professionelles Retrofit von Reishauer Zahnradschleifmaschinen anbietet. " +
            "Zwischenzeitlich haben wir auch ein komplett eigenes Spektrum an Zahnrad-, Schnecken- und " +
            "Gewindeschleifmaschinen auf unserer modularen und patentieren Plattform im Portfolio. " +
            "Als Systemanbieter bekommen Sie von uns das komplette Zubehör wie Automationen, Spannmittel, Filtrationen " +
            "und Schleifwerkzeuge mit Fachberatung speziell für Ihre Einsatzgebiete ausgelegt.",

        products: "Unsere Produkte",

        villingendorf: "Von Villingendorf in die ganze Welt",

        villingendorf_text: "Alle unsere Maschinen werden in Villingendorf bei Rottweil (Baden-Württemberg)" +
            ", dem Herz des deutschen Werkzeugmaschinenbaus, entwickelt, hergestellt und weltweit exportiert. " +
            "Kommen Sie vorbei! Ein Besuch lohnt sich in Rottweil, der ältesten Stadt Baden-Württembergs, " +
            "der Heimat, der weltweit bekannten Rottweiler Hunde und des Thyssen-Krupp-Aufzugtestturm mit Deutschlands " +
            "höchster Aussichtsplattform.",

        christmas_text: "Liebe Geschäftspartnerinnen und Geschäftspartner, <br><br>\n" +
            "        die Weihnachtszeit liefert uns eine stimmungsvolle Gelegenheit, um innezuhalten und Danke für ein\n" +
            "        gutes Jahr zu sagen. Gemeinsam haben wir viel geschafft. Schön, dass wir uns aufeinander verlassen können.\n" +
            "        Vertrauen ist eben ein großes Geschenk.<br>\n" +
            "        Wir wünschen Ihnen ein gesegnetes Fest\n" +
            "        im Kreise Ihrer Lieben und Familien und viel Gesundheit, Glück und Erfolg im neuen Jahr.",

        opening_hours: " Bitte beachten Sie unsere Öffnungszeiten zwischen Weihnachten und Neujahr:<br>" +
            "           Vom 27. – 29.12.2023 haben wir geschlossen und sind ab dem 02.01.2024 gewohnt zuverlässig wieder für Sie da."
    },

    products: {
        geargrinding: "Zahnradschleifmaschinen",
        profiling: "Profiliermaschinen",
        clamping: "Spannsysteme",
        more: "Mehr",

        technical_data: "Technische Daten",
        request: "Jetzt anfragen",
        advantages: "Ihre Vorteile",
    },

    clamping: {
        text: "Wir haben schon früh erkannt, dass ein hochpräzises Spannsystem für eine hohe Schleifqualität und " +
            "kurze Zykluszeit unumgänglich ist. Aus diesem Grunde entwickeln und fertigen wir für alle unsere Maschinen " +
            "die Spannvorrichtungen bei uns im Hause. Alle unsere Spannmittellösungen basieren auf dem Prinzip unseres " +
            "Quick Lock Schnellspannsystem. Dieses System bietet eine steife Anbindung an die Maschine, " +
            "sekundenschnelle Wechselzeiten sowie eine hohe Wiederholgenauigkeit. Unser Portfolio bietet verschiedene " +
            "modulare Spannsysteme für Wellen- und Räderteile. Durch den modularen Aufbau können die Lieferzeiten " +
            "kurzgehalten werden. Sollte unser Standartsystem einmal nicht passen werden kundenspezifische " +
            "Sonderlösungen entwickelt."
    },

    company: {
        text: "Sehr geehrte Geschäftspartner,<br><br>" +
            "die Firma Burri Werkzeugmaschinen GmbH & Co. KG wurde von mir im Jahre 2002 gegründet, nachdem ich jahrzehntelang " +
            "Erfahrung im Zahnflankenschleifen als Servicetechniker bei der Firma Reishauer sammeln konnte.<br>" +
            "Schon seit 1999 (3 Jahre vor der Firmengründung) ersetzte ich die Profilierwechselräder und manuelle " +
            "Verstellung der A-Achse an Reishauermaschinen der Typen RZ301 und RZ362 durch NC-Achsen. " +
            "Somit war die weltweit erste wechselradfreie und voll CNC gesteuerte Reishauer Wälzschleifmaschine eine " +
            "von Burri nachgerüstete RZ362A.<br><br>" +
            "Entscheiden Sie sich für eine Burri-Maschine aus einem inhabergeführten mittelständischen Unternehmen mit " +
            "hochmotivierten kompetenten Mitarbeitern.<br>" +
            "Ich garantiere persönlich für beste Qualität und hohe Wirtschaftlichkeit.<br><br>" +
            "Dieter Burri <br> Inhaber und Geschäftsführer",


        history: "Geschichte",
        sustainability: "Nachhaltigkeit",
        sustainability_text: "Wir sprechen nicht nur darüber, wir setzen es auch um - und das schon immer! " +
            "Alle unsere Zahnradschleifmaschinen sind unschlagbar in deren Stromverbrauch und mit Abstand die " +
            "energieeffizientesten Verzahnungsschleifmaschinen, die momentan auf dem Markt erhältlich sind.<br><br>" +
            "Wie schaffen wir das? Unsere sehr wartungsfreundliche Hightech-Steuerung mit Acopos-Multi-Antriebsystem " +
            "und Netzrückspeisung aus dem Hause B&R (ABB) wird exakt auf den jeweiligen Maschinentyp angepasst. " +
            "Dies trägt einen sehr großen Teil dazu bei. Ein weiterer ausschlaggebender Faktor ist das kompakte " +
            "Hydraulikaggregat (nur 3,5 Liter Volumen), welches nur bei Druckbedarf angesteuert wird.",
        membership: "Verbandsmitgliedschaft",
        membership_text: "Wir sind Mitglied bei",
        team: "Unser Team"
    },

    career: {
        header: "Sie arbeiten gerne mit innovativer Technik und suchen eine neue spannende Herausforderung?",
        header2: "Dann sind Sie hier genau richtig!",
        text: "Die Burri Werkzeugmaschinen  GmbH & Co.KG gehört zu den führenden Herstellern von Werkzeugmaschinen und " +
            "hat sich auf Konstruktion, Entwicklung und Herstellung von Zahnradschleifmaschinen spezialisiert, " +
            "die Ihren Einsatz bei zahlreichen Zahnrad- und Getriebeherstellern finden. " +
            "Mit ihrem Hauptsitz in Villingendorf und den weltweiten Niederlassungen / Vertretungen " +
            "ist Burri global tätig. Wir liefern Präzisionsschleifmaschinen an zahlreiche Kunden aus den Branchen " +
            "Automotive, Energie, Getriebetechnik, Luftfahrt, Maschinenbau, Schiffs- und Verkehrstechnik, Zahnradfertigung.<br><br>" +
            "Für unsere Aufgaben und Ziele sind wir daher auf der Suche nach hochmotivierten Mitarbeiter*innen, die gemeinsam mit uns etwas erreichen wollen.",
        team: "Zur Verstärkung unseres Teams suchen wir:",
        text2: "Wir bieten Ihnen: <br>" +
            "&#9679 Eine interessante und abwechslungsreiche Tätigkeit sowie ein dynamisches, offenes Arbeitsklima<br>" +
            "&#9679 Eine umfassende Einarbeitung in Ihren Aufgabenbereich und unsere Geräte<br>" +
            "&#9679 Eine leistungsgerechte Bezahlung<br>" +
            "&#9679 Die Rahmenbedingungen eines innovativen, dauerhaft erfolgreichen Unternehmens",
        apply: "Interessiert? Dann bewerben Sie sich unter <a href='mailto:karriere@burri.de'>karriere@burri.de</a><br>" +
            "Wir freuen uns auf Sie!"

    },

    current: {
        current: "Aktuelles",
        fairs: "Messen",
        innovation: "Neuheiten",

        inno1: "Unsere BZ130 Doppelspindelschleifmaschine wurde modifiziert und ist jetzt auf unserer neuen Plattform" +
            " mit Maschinenbett und Hauptschlitten aus Mineralguss sowie optimierter Achsanordnung der Werkstückspindeln" +
            " mit noch kürzerer Chip to Chip Time unter 2 Sekunden erhältlich.",
        inno2: "Neues Spannsystem in Federhülsenausführung für Bohrungsdurchmesser ab 10mm."

    },

    contact: {
        germany: "Burri Deutschland",
        international: "Burri International",
        phone: "Telefon",
        fax: "Fax",
        mail: "E-Mail"
    },

    request: {
        name: "Vor- und Zuname",
        company: "Firma",
        mail: "E-Mail",
        subject: "Betreff",
        message: "Nachricht",
        send: "Senden",
        cancel: "Abbrechen"
    }
};

