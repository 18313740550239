import db from "@/firebaseConfig";
import Engine from "@/components/products/Engine";
import Contact from "@/components/contact/contact";
import Exhibition from "@/components/news/Exhibition";
import EngineProxy from "@/components/products/EngineProxy";

export class DBService {
    static dbService: DBService;
    private slideshow: any[] = [];
    private engine: Engine | undefined;
    private proxies: EngineProxy[] = [];
    private contacts: Contact[] = [];
    private jobs: any[] = [];
    private history: any[] = [];
    private exhibitions: any[] = [];
    private innovation: any[] = [];
    private clamping_imgs: any[] = [];
    private clamping_advantages: any[] = [];
    private clamping_data: any[] = [];
    private locale = localStorage.getItem("lang");

    static getInstance(): DBService {
        if (!DBService.dbService) {
            DBService.dbService = new DBService();
        }
        return DBService.dbService;
    }

    async getSlideShow() {
        let slides: any = [];
        await db.collection('home').doc('slideshow').get().then(function (doc) {
            slides = doc.data();
        })
        return this.slideshow = slides;
    }

    async getProxies() {
        if (this.proxies.length == 0) {
            this.proxies = (await db.collection('engines_proxy').withConverter(this.proxyConverter)
                .orderBy('position').get()).docs.map(doc => doc.data())
        }
        return this.proxies;
    }

    async getEngineById(id: string) {
        let engine;
        if (this.locale) {
            const enginesRef = db.collection("engines_".concat(this.locale));
            await enginesRef.doc(id).withConverter(this.engineConverter).get().then(function (doc) {
                engine = doc.data();
            })
        } else {
            const enginesRef = db.collection("engines_de");
            await enginesRef.doc(id).withConverter(this.engineConverter).get().then(function (doc) {
                engine = doc.data();
            })
        }
        return this.engine = engine;
    }

    async getClampingImgs() {
        let clamping_imgs: any = [];
        const clampingRef = db.collection('clamping_tools');
        await clampingRef.doc('imgs').get().then(function (doc) {
            clamping_imgs = doc.data();
        })
        return this.clamping_imgs = clamping_imgs;
    }

    async getClampingAdvantages() {
        let clamping_advantages: any = [];
        const clampingRef = db.collection('clamping_tools');
        await clampingRef.doc('advantages').get().then(function (doc) {
            clamping_advantages = doc.data();
        })
        return this.clamping_advantages = clamping_advantages;
    }

    async getClampingData() {
        let clamping_data: any = [];
        const clampingRef = db.collection('clamping_tools');
        await clampingRef.doc('technical_data').get().then(function (doc) {
            clamping_data = doc.data();
        })
        return this.clamping_data = clamping_data;
    }

    async getHistory() {
        if (this.history.length == 0) {
            this.history = (await db.collection('history').orderBy("year", "desc").get())
                .docs.map(doc => doc.data())
        }
        return this.history;
    }


    async getContacts() {
        if (this.contacts.length == 0) {
            db.collection('contacts').withConverter(this.contactConverter).orderBy('id')
                .onSnapshot({includeMetadataChanges: false}, (snapShot) => {
                    snapShot.forEach((contact) => {
                        this.contacts.push(contact.data());
                    });
                });
        }
        return this.contacts;
    }

    getJobs() {
        if (this.jobs.length <= 0) {
            db.collection('career').onSnapshot({includeMetadataChanges: false}, (snapShot) => {
                snapShot.forEach((job) => {
                    this.jobs.push(job.data());
                });
            });
        }
        return this.jobs
    }

    async getExhibitions() {
        if (this.exhibitions.length <= 0) {
            db.collection('news').withConverter(this.exhibitionConverter).onSnapshot({includeMetadataChanges: false}, (snapShot) => {
                snapShot.forEach((exhibition) => {
                    this.exhibitions.push(exhibition.data());
                });
            });
        }
        return this.exhibitions
    }

    async getInnovation() {
        if (this.innovation.length <= 0) {
            db.collection('innovation').onSnapshot({includeMetadataChanges: false}, (snapShot) => {
                snapShot.forEach((inno) => {
                    this.innovation.push(inno.data());
                });
            });
        }
        return this.innovation
    }

    proxyConverter = {
        toFirestore: function (proxy: EngineProxy) {
            return {
                id: proxy.id,
                name: proxy.name,
                position: proxy.position,
                subtitle: proxy.subtitle,
                type: proxy.type,
                thumbnail: proxy.thumbnail
            }
        },

        fromFirestore: function (snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new EngineProxy(data.id, data.name, data.position, data.subtitle, data.thumbnail, data.type);
        }
    }

    engineConverter = {
        toFirestore: function (engine: Engine) {
            return {
                id: engine.id,
                name: engine.name,
                header: engine.header,
                imgs: engine.imgs,
                technical_data: engine.technical_data,
                notes: engine.notes,
                description: engine.description,
                advantages: engine.advantages
            }
        },
        fromFirestore: function (snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new Engine(data.id, data.name, data.header, data.imgs, data.technical_data,
                data.notes, data.description, data.advantages)
        }
    }

    contactConverter = {
        toFirestore: function (contact: Contact) {
            return {
                id: contact.id,
                img: contact.img,
                location: contact.location,
                firstname: contact.firstname,
                lastname: contact.lastname,
                phone: contact.phone,
                mail: contact.mail,
                fax: contact.fax,
                adress: contact.adress
            }
        },

        fromFirestore: function (snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new Contact(data.id, data.img, data.location, data.firstname, data.lastname, data.phone, data.mail,
                data.fax, data.adress);

        }
    }

    exhibitionConverter = {
        toFirestore: function (exhibition: Exhibition) {
            return {
                id: exhibition.id,
                img: exhibition.img,
                name: exhibition.name,
                description: exhibition.description,
                start: exhibition.start,
                end: exhibition.end,
                city: exhibition.city,
                country: exhibition.country,
                href: exhibition.href,
            }
        },

        fromFirestore: function (snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new Exhibition(data.id, data.img, data.name, data.description, data.startDate.toDate(), data.endDate.toDate(), data.city, data.country, data.href);
        }
    }
}