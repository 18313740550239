import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CountryFlag from 'vue-country-flag'
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import {Locales} from "@/i18n/locales";
import {messages} from "@/i18n";
import Meta from 'vue-meta';
import vuetify from './plugins/vuetify';

Vue.use(Meta);
Vue.use(VueI18Next);

// @ts-ignore
i18next.init({
    lng: localStorage.getItem("lang"),
    fallbackLng: 'de',
    resources: {
        en: {translation: messages[Locales.EN]},
        de: {translation: messages[Locales.DE]}
    }
});

const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false
Vue.component('country-flag', CountryFlag)

new Vue({
    metaInfo: {
        title: 'Burri Werkzeugmaschinen',
        meta: [
            {name: 'description', content: 'Burri Werkzeugmaschinen'},
            {
                name: 'keywords',
                content: 'gear grinding, gear, Burri, Werkzeugmaschinen, Zahnradschleifmaschinen, Schleiftechnologie, ' +
                    'Zahnrad, Maschinenbau, Werkzeugbau, Germany, Deutschland, Machine Tools, Clamping Tools, Spannsysteme'
            },
        ],
    },

    i18n: i18n,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
