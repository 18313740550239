import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Contact from "@/views/Contact.vue";
import Products from "@/views/Products.vue";
import ProductContainer from "@/components/products/ProductContainer.vue";
import Clamping from "@/components/products/Clamping.vue";
import Company from "@/views/Company.vue";
import Career from "@/views/Career.vue";
import News from "@/views/News.vue";
import Impressum from "@/views/Impressum.vue";
import Datenschutz from "@/views/Datenschutz.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        alias: '/home',
        name: 'home',
        component: Home
    },

    {
        path: '/products',
        name: 'products',
        component: Products,
    },

    {
        path: '/products/:id',
        name: 'enginePage',
        component: ProductContainer,
    },

    {
        path: '/clamping',
        name: 'clamping',
        component: Clamping,
    },

  {
    path: '/company',
    name: 'Company',
    component: Company
  },
  {
    path: '/career',
    name: 'Career',
    component: Career
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },

    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: Datenschutz
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
