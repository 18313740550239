
    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component
    export default class InnovationCard extends Vue {
        @Prop() innovation!: any;

        getLocale() {
            let locale = localStorage.getItem("lang");
            if (locale) {
                return locale
            } else {
                return 'de'
            }
        }

      readFile(file: string) {
        window.open(file, '_blank') //to open in new tab
      }
    }
